import { Typography } from '@mui/material'
import React from 'react'

const UpgradeMessage = ({data}) => {

    let { status, message } = data;

    switch (status) {
      case "failed":
        status = "Idle";
        break;
      case "not_exist":
        status = "Invalid Key";
        break;
      case "success":
        status = "Upgrade Sent";
        break;
      case "error":
        status = "Error";
        break;
      case "upgrade_processing":
        status = "Upgrade Processing";
        break;
      case "failed_upgrade":
        status = "Upgrade Failed";
        break;
      case "failed_renew":
        status = "Renew Failed";
        break;
        case "check_processing":
        status = "Key Processing";
        message = "Check back in a few seconds"
        break;
      default:
        break;
    }

  return (
    <div>
        <Typography sx={{color: 'black'}} variant="h5" gutterBottom align="center">
            STATUS: {status}
        </Typography>
        <Typography sx={{color: 'black'}} variant="h5" align="center">
            Message: 
        </Typography>
        <Typography color="black" gutterBottom variant="h6" align="center">
            {message} 
        </Typography>
    </div>
  )
}

export default UpgradeMessage