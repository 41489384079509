import { useEffect, useState, useContext } from "react";
import classes from "./App.module.css";
import UsersList from "./Components/UsersList";
import MySnackbar from "./Components/UI/MySnackbar";
import TokenModal from "./Components/UI/TokenModal";
import {
  SearchIconWrapper,
  StyledInputBase,
  Search,
} from "./Components/UI/SearchComponents";
import { tokenCtx } from "./store/TokenProvider";

import SearchIcon from "@mui/icons-material/Search";

//1. Avishay needs to update he's server to check if user already exists;
//2. Make sure which API's to upproach on Renew, Upgrade (Update then the button to the response); 
//3. something with text cutting

function App() {
  const [selected, setSelected] = useState([]);
  const { token } = useContext(tokenCtx);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const { tokenHandler } = useContext(tokenCtx);

  useEffect(() => {
    const accessToken = localStorage.getItem("kids-access-token");
    if (accessToken) {
      setIsModalOpen(false);
      tokenHandler(accessToken);
    }
  }, []);

  useEffect(() => {
    if (!token) return;

    let response;
    fetch("https://admin.spotifyman.com/customers/", {
      mode: "cors",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        response = res;
        return res.json();
      })
      .then((data) => {
        if (data.message === "Invalid access token") {
          localStorage.removeItem('kids-access-token')
          throw new Error("Invalid Token");
        }

        if (response.status === 200 || response.status === 201) {
          setUsers(
            data.sort((a, b) => {
              return (
                new Date(b.created_date).getTime() -
                new Date(a.created_date).getTime()
              );
            })
          );
        }
      })
      .catch((err) => {
        setError(err.message);
        console.log(err);
      });
  }, [token]);

  const removeUserHandler = (id) => {
    setOpen(true);
    setUsers((preValue) => {
      return preValue.filter((u) => u._id !== id);
    });

    setSelected((preValue) => {
      return preValue.filter((u) => u._id !== id);
    });
  };

  const replaceUpdatedUser = (id, user) => {
    setOpen(true);
    setUsers((preValue) => {
      const userIndex = preValue.findIndex((u) => u._id === id);
      if (userIndex === -1) {
        return [user, ...preValue];
      } else {
        const newArr = [...preValue];
        newArr[userIndex] = user;
        return newArr;
      }
    });
  };

  const addUserHandler = (user) => {
    setOpen(true);
    setUsers((preValue) => {
      return [user, ...preValue];
    });
  };

  return (
    <div className={classes.app}>
      <TokenModal open={isModalOpen} setOpen={setIsModalOpen} />
      <MySnackbar open={open} setOpen={setOpen} />
      <div style={{ padding: "0 20px" }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
      </div>
      <UsersList
        search={search}
        selected={selected}
        setSelected={setSelected}
        users={users}
        errors={error}
        setUsers={setUsers}
        setOpen={setOpen}
        addUserHandler={addUserHandler}
        removeUserHandler={removeUserHandler}
        replaceUpdatedUser={replaceUpdatedUser}
      />
    </div>
  );
}

export default App;
