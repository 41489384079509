import React from "react";
import { Snackbar, Alert } from "@mui/material";

function MySnackbar({setOpen, open}) {

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
       <Alert onClose={handleClose} severity="success">Successful Action</Alert>
    </Snackbar>
  );
}

export default MySnackbar;
