import { useState } from "react";
import { Modal, Typography, Box } from "@mui/material";
import React from "react";
import RequestButton from "./RequestButton";
import SelectCountry from "./SelectCountry";
import RenewMessage from "./MessageDisplays.js/RenewMessage";
import UpgradeMessage from "./MessageDisplays.js/UpradeMessages";
import KeyInfoDisplay from "./MessageDisplays.js/KeyInfoDisplay";
// import classes from './ManageMenu.module.css';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ManageMenu({ k, open, setOpen, login, password }) {
  const [data, setData] = useState({ renew: null, upgrade: null, info: null });
  const [chosenCountry, setChosenCountry] = useState({});
  const [error, setError] = useState('');

  const onChangeCountryHandler = (value) => {
    setChosenCountry(value);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={style}>
        <Box sx={{ marginBottom: "25px", textAlign: "center" }}>
          {data.renew && <RenewMessage data={data} />}
          {data.upgrade && <UpgradeMessage data={data} />}
          {data.info && <KeyInfoDisplay data={data} />}
        </Box>
        <Typography align="center" color={{color: 'red'}} variant="subtitle1" gutterBottom>{error}</Typography>
        <div
          style={{
            display: "flex",
            displayDirection: "row",
            justifyContent: "center",
          }}
        >
          <RequestButton
            setData={setData}
            countryCode={chosenCountry.code}
            text={"Upgrade"}
            setError={setError}
            login={login}
            password={password}
            upgrade={true}
            k={k}
          />

          <RequestButton
            setData={setData}
            setError={setError}
            countryCode={chosenCountry.code}
            text={"Renew"}
            login={login}
            password={password}
            renew={true}
            k={k}
          />
          <RequestButton
            setData={setData}
            setError={setError}
            countryCode={chosenCountry.code}
            text={"Key Info"}
            login={login}
            password={password}
            info={true}
            k={k}
          />
        </div>
        <div>
          <SelectCountry
          setCountryMsg={setError}
            onChangeCountryHandler={onChangeCountryHandler}
            chosenCountry={chosenCountry}
          />
        </div>
      </Box>
    </Modal>
  );
}

export default ManageMenu;
