import { useState } from "react";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import ManageMenu from "./ManageMenu";

function Manage({ k, login, password }) {
  const [open, setOpen] = useState(false);
  // console.log(k)
  const toggleMenu = () => {
    setOpen((preValue) => !preValue);
  };

  return (
    <div
      style={{
        position: "relative",
    
      }}
    >
      <Button
        sx={{
          fontWeight: "bold",
          color: "black",
          padding: "0",
        }}
        variant="text"
        onClick={toggleMenu}
      >
        Manage
      </Button>

      <ManageMenu
        open={open}
        setOpen={setOpen}
        k={k}
        login={login}
        password={password}
      />
    </div>
  );
}

export default Manage;
