import { useState, useContext } from "react";
import { tokenCtx } from "../../store/TokenProvider";
import {
  IconButton,
  TableCell,
  TableRow,
  TextField,
  MenuItem,
  Select,
  Box,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import useValidate from "../../hooks/useValidate";

function PostUserCell({ _id, deleteHandler, addUser, setError }) {
  const [status, setStatus] = useState(false);

  const { token } = useContext(tokenCtx);

  const {
    onChangeHandler: onChangeNameHandler,
    inputRef: nameRef,
    isValid: isNameValid,
  } = useValidate((value) => {
    if (value.trim().length !== 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeEmailHandler,
    inputRef: emailRef,
    isValid: isEmailValid,
  } = useValidate((value) => {
    if (
      value.trim().length !== 0 &&
      value.includes("@") &&
      value.includes(".")
    ) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeAmountHandler,
    inputRef: amountRef,
    isValid: isAmountValid,
  } = useValidate((value) => {
    if (value.trim().length !== 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangePhoneHandler,
    inputRef: contactRef,
    isValid: isContactRef,
  } = useValidate((value) => {
    if (value.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeKeyHandler,
    inputRef: keyRef,
    isValid: isKeyValid,
  } = useValidate((value) => {
    const arr = value.split("-");

    if (arr.length === 4) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].length !== 4) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangePasswordHandler,
    inputRef: passwordRef,
    isValid: isPasswordValid,
  } = useValidate((value) => {
    if (value.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeMethodHandler,
    inputRef: methodRef,
    isValid: isMethodValid,
  } = useValidate((value) => {
    if (value.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const onChangePaymentStatus = (e, child) => {
    setStatus(e.target.value);
  };

  const isFormValid =
    isAmountValid &&
    isEmailValid &&
    isNameValid &&
    isContactRef &&
    isKeyValid &&
    isMethodValid &&
    isPasswordValid;

  const postUserHandler = () => {
    if (!isFormValid) {
      return;
    }

    const body = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      paid_amount: +amountRef.current.value,
      contact_info: contactRef.current.value,
      key: keyRef.current.value,
      payment_method: methodRef.current.value,
      payment_status: status,
      password: passwordRef.current.value,
    };

    const postUser = async () => {
      setError("");

      return fetch("https://admin.spotifyman.com/customers/", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status === 409) {
          console.log("row");
          throw new Error(
            "User is already exists please change email or username."
          );
        }
        if (res.status === 200 || res.status === 201) {
          nameRef.current.value = "";
          emailRef.current.value = "";
          amountRef.current.value = "";
          contactRef.current.value = "";
          keyRef.current.value = "";
          methodRef.current.value = "";
          passwordRef.current.value = "";
          return res.json();
        } else {
          throw new Error("Failed to create user. ");
        }
      });
    };

    postUser()
      .then((data) => {
        addUser(data);
        deleteHandler(_id);
      })
      .catch((err) => {
        console.log(err);
        setError(err.message);
      });
  };

  return (
    <>
      <TableCell
        sx={{ border: "none", fontWeight: "bold", width: "10%" }}
        align="right"
      >
        <IconButton onClick={postUserHandler} disabled={!isFormValid}>
          <DoneIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            deleteHandler(_id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>

      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="right">
        <TextField
          id="filled-basic"
          label="Name"
          onChange={onChangeNameHandler}
          sx={{ border: "none", fontWeight: "bold" }}
          inputRef={nameRef}
          variant="standard"
        />
      </TableCell>

      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="right">
        <TextField
          id="filled-basic"
          label="Key"
          inputRef={keyRef}
          onChange={onChangeKeyHandler}
          variant="standard"
        />
      </TableCell>

      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="right">
        <TextField
          inputRef={emailRef}
          onChange={onChangeEmailHandler}
          id="filled-basic"
          label="Email"
          variant="standard"
        />
      </TableCell>

      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="right">
        <TextField
          id="filled-basic"
          label="Password"
          inputRef={passwordRef}
          onChange={onChangePasswordHandler}
          variant="standard"
        />
      </TableCell>

      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="right">
        <TextField
          id="filled-basic"
          label="Paid Amount"
          inputRef={amountRef}
          onChange={onChangeAmountHandler}
          variant="standard"
        />
      </TableCell>

      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="right">
        <TextField
          onChange={onChangeMethodHandler}
          inputRef={methodRef}
          id="filled-basic"
          label="Payment Method"
          variant="standard"
        />
      </TableCell>

      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="center">
        <Box sx={{ minWidth: 200 }}>
          <FormControl sx={{ width: "70%" }}>
            <InputLabel id="demo-simple-select-label">
              Payment Status
            </InputLabel>
            <Select
              label="Payment Status"
              onChange={onChangePaymentStatus}
              value={status}
            >
              <MenuItem value={true}>paid</MenuItem>
              <MenuItem value={false}>unpaid</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </TableCell>

      {/* <TableCell sx={{ border: "none", fontWeight: "bold" }} align="right">
        <TextField
          id="filled-basic"
          label="payment status"
          variant="standard"
        />
      </TableCell> */}

      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="right">
        <TextField
          id="filled-basic"
          label="Contact info"
          inputRef={contactRef}
          onChange={onChangePhoneHandler}
          variant="standard"
        />
      </TableCell>
    </>
  );
}

export default PostUserCell;
