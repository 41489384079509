import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AcceptModal({
  open,
  setOpen,
  deleteSelectedUsers,
  selectedCustomers,
}) {
  const handleClose = () => setOpen(false);

  const onAccept = () => {
    deleteSelectedUsers(selectedCustomers);
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ color: "black" }}
          gutterBottom
          id="modal-modal-title"
          variant="h6"
          align="center"
          component="h2"
        >
          Are you sure to delete {selectedCustomers.length} selected users ?
        </Typography>
        <Button
          sx={{ float: "right", margin: "20px 20px 0" }}
          variant="contained"
          onClick={onAccept}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );
}
