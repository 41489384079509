import { useState, useEffect, useContext } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import PostUserCell from "./PostUser/PostUserCell";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import UserRow from "./UI/UserRow";

export default function CustomersTable({
  setOpen,
  users,
  setUsers,
  error,
  selected,
  setSelected,
  removeUserHandler,
  replaceUpdatedUser,
  addUserHandler,
}) {
  const [addUsers, setAddUsers] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [isEditing, setIsEditing] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const matches = useMediaQuery("(min-width:960px)");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    setSelected((preValue) => {
      const foundedIndex = preValue.findIndex((r) => r._id === id);
      if (foundedIndex !== -1) {
        const filtered = preValue.filter((r, index) => index !== foundedIndex);
        if (filtered.length === 0) {
          setIsEditing(false);
        }
        return filtered;
      } else {
        const selectedCustomer = users.find((r) => r._id === id);
        return [...preValue, selectedCustomer];
      }
    });
  };

  const pageChangeHandler = (e, page) => {
    setPage(page);
  };

  const isSelected = (id) => selected.findIndex((r) => r._id === id) !== -1;

  const deleteFromAddedUsers = (id) => {
    setAddUsers((preValue) => {
      return preValue.filter((u) => u._id !== id);
    });
  };

  if (users.length === -1) {
    return <h1>Loading...</h1>;
  }
  console.log(errorMsg);
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography
          align="center"
          sx={{ color: "red", padding: "10px 0 20px 0" }}
          variant="h6"
        >
          {errorMsg}
        </Typography>
        {error && (
          <Typography
            varaint="h4"
            sx={{ color: "red", textAlign: "center", padding: "20px 0" }}
          >
            {error}
          </Typography>
        )}
        <EnhancedTableToolbar
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          removeUser={removeUserHandler}
          updateUsers={replaceUpdatedUser}
          setAddUsers={setAddUsers}
          numSelected={selected.length}
          selectedCustomers={selected}
          handleCheckbox={handleClick}
          isSelected={isSelected}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 50 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={users.length}
            />
            <TableBody>
              {addUsers.map((data, index) => {
                return (
                  <TableRow sx={{ width: "100%" }} key={data._id}>
                    {matches ? (
                      <PostUserCell
                        setError={setErrorMsg}
                        addUser={addUserHandler}
                        deleteHandler={deleteFromAddedUsers}
                        _id={data._id}
                      />
                    ) : (
                      <UserRow
                        setIsEditing={setIsEditing}
                        row={data}
                        isNew={true}
                        matches={matches}
                        labelId={data._id}
                        setAddUsers={setAddUsers}
                        updateUser={replaceUpdatedUser}
                        removeUser={removeUserHandler}
                        addUser={addUserHandler}
                      />
                    )}
                  </TableRow>
                );
              })}
              {users.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${row._id}`;

                if (
                  index < (page + 1) * rowsPerPage &&
                  index >= page * rowsPerPage
                ) {
                  return (
                    <UserRow
                      isEditing={isEditing}
                      updateUser={replaceUpdatedUser}
                      removeUser={removeUserHandler}
                      key={row._id}
                      row={row}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      matches={matches}
                      handleClick={handleClick}
                    />
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          onPageChange={pageChangeHandler}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(e.target.value);
          }}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
        />
      </Paper>
    </Box>
  );
}
