import React from "react";

import {
  TableCell,
  MenuItem,
  Select,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import CopyButton from "./ADD-ONS/CopyButton";

const IsEditing = React.forwardRef(
  (
    {
      inputLabel,
      value,
      isEditing,
      defaultValue,
      label,
      onChangeFunc,
      setRowData,
      setIsChanged,
      search,
      isSelect,
    },
    ref
  ) => {
    const onChangeHandler = (e) => {
      setIsChanged(true);
      setRowData((preValue) => {
        return { ...preValue, [label]: e.target.value };
      });
      onChangeFunc();
    };

    return (
      <div
        style={{ marginBottom: "5px", position: "relative", display: "block" }}
      >
        {!isSelect ? (
          <>
            <TextField
              inputProps={!isEditing && { disabled: true }}
              sx={{
                marginBottom: "20px",
                ".disabled": {
                  color: "black",
                },
                width: "32%",
              }}
              InputProps={{ style: { color: "black", borderColor: "black" } }}
              onChange={onChangeHandler}
              inputRef={ref}
              id="standard-basic"
              label={inputLabel}
              variant="standard"
              defaultValue={defaultValue}
            />
            <CopyButton value={defaultValue} />
          </>
        ) : (
          <TableCell
            sx={{ border: "none", fontWeight: "bold", padding: "15px 0 25px" }}
            align="left"
          >
            <Box sx={{ minWidth: 200, display: "inline-block" }}>
              <FormControl sx={{ width: "60%" }}>
                <InputLabel id="demo-simple-select-label">
                  Payment Status
                </InputLabel>
                <Select
                  inputProps={!isEditing && { disabled: true }}
                  label="Payment Status"
                  onChange={onChangeHandler}
                  inputRef={ref}
                  defaultValue={defaultValue}
                >
                  <MenuItem value={"paid"}>paid</MenuItem>
                  <MenuItem value={"unpaid"}>unpaid</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <CopyButton value={defaultValue} />
          </TableCell>
        )}
      </div>
    );
  }
);

export default IsEditing;
