import { useState, useRef } from "react";

const useValidate = (cb) => {
  const [isValid, setIsValid] = useState(false);

  const inputRef = useRef();

  const onChangeHandler = () => {
    if (cb(inputRef.current.value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return { onChangeHandler, isValid, inputRef };
};

export default useValidate;
