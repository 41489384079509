import React, { useEffect, useState, useContext } from "react";
import { Checkbox, TableRow, TableCell, Typography } from "@mui/material";

import CollapseUserRow from "./CollapseUserRow";
import EditingCell from "./EditingCell";
import { tokenCtx } from "../../store/TokenProvider";
import SelectStatus from "./SelectStatus";
import Template from "./ADD-ONS/Template";
import Manage from "./ADD-ONS/Manage";


function UserRow({
  updateUsers,
  isEditing,
  setAddUsers,
  row,
  isItemSelected,
  matches,
  labelId,
  handleClick,
  updateUser,
  removeUser,
  isNew,
  addUser,
  setIsEditing
}) {
  const [open, setOpen] = useState(false);
  const [editedUser, setEditedUser] = useState({ ...row });
  const [isChanged, setIsChange] = useState(false);

  const {token} = useContext(tokenCtx);

  const collapseHandler = () => {
    setOpen((preValue) => !preValue);
  };

  useEffect(() => {

    if (!isEditing && isChanged) {
      let body = { ...editedUser };

      const updateRequest = async () => {

        return fetch(
          `https://admin.spotifyman.com/customers/${editedUser._id}`,
          {
            method: "PATCH",
            body: JSON.stringify({ ...body }),
            headers: {
              Authorization:
                token,
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {
          if (res.status === 200 || res.status === 201) {
            return res.json();
          }
          throw new Error("Failed updating user.");
        });
      };

      updateRequest()
        .then((data) => {
          updateUser(editedUser._id, data);
        })
        .catch((err) => console.log(err.message));
    }
  }, [isEditing, isChanged, editedUser, token]);

  return (
    <>
      <TableRow
        onClick={collapseHandler}
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row._id}
        selected={isItemSelected}
      >
        {matches && (
          <TableCell
            padding="checkbox"
            sx={{ border: "none", fontWeight: "bold" }}
          >
            <Checkbox
              onClick={(event) => handleClick(event, row._id)}
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId,
              }}
            />
          </TableCell>
        )}

        <TableCell
          sx={{
            border: "none",
            fontWeight: "bold",
            width: "160",
          }}
          align={matches ? "center" : "left"}
          component="th"
          id={labelId}
          scope="row"
          padding="normal"
        >
          {matches ? (
            isNew ? (
              "--New User--"
            ) : isItemSelected && isEditing ? (
              <EditingCell
                label={"name"}
                inputLabel={"Name"}
                setEditedUser={setEditedUser}
                defaultValue={row.name}
                setIsChange={setIsChange}
              />
            ) : (
              <Template>
                {row.name}
              </Template>
            )
          ) : (
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {isNew ? "--New User--" : row.name}
            </Typography>
          )}
        </TableCell>

        {matches && (
          <>
            <TableCell
              sx={{ border: "none", fontWeight: "bold" }}
              align="center"
            >
              {isItemSelected && isEditing ? (
                <EditingCell
                  inputLabel={"Key"}
                  label={"key"}
                  setEditedUser={setEditedUser}
                  defaultValue={row.key}
                  setIsChange={setIsChange}
                />
              ) : (
                <Template>{row.key}</Template>
              )}
            </TableCell>

            <TableCell
              sx={{ border: "none", fontWeight: "bold" }}
              align="center"
            >
              {isItemSelected && isEditing ? (
                <EditingCell
                inputLabel={"Email"}
                  label={"email"}
                  setEditedUser={setEditedUser}
                  defaultValue={row.email}
                  setIsChange={setIsChange}
                />
              ) : (
                <Template>{row.email}</Template>
              )}
            </TableCell>

            <TableCell
              sx={{ border: "none", fontWeight: "bold" }}
              align="center"
            >
              {isItemSelected && isEditing ? (
                <EditingCell
                inputLabel={"Password"}
                  label={"password"}
                  setEditedUser={setEditedUser}
                  defaultValue={row.password}
                  setIsChange={setIsChange}
                />
              ) : (
                <Template>{row.password}</Template>
              )}
            </TableCell>

            <TableCell
              sx={{ border: "none", fontWeight: "bold" }}
              align="center"
            >
              {isItemSelected && isEditing ? (
                <EditingCell
                inputLabel={"Paid amount"}
                  label={"paid_amount"}
                  setEditedUser={setEditedUser}
                  defaultValue={row.paid_amount}
                  setIsChange={setIsChange}
                />
              ) : (
                <Template>{row.paid_amount}</Template>
              )}
            </TableCell>

            <TableCell
              sx={{ border: "none", fontWeight: "bold" }}
              align="center"
            >
              {isItemSelected && isEditing ? (
                <EditingCell
                inputLabel={"Payment method"}
                  label={"payment_method"}
                  setEditedUser={setEditedUser}
                  defaultValue={row.payment_method}
                  setIsChange={setIsChange}
                />
              ) : (
                <Template>{row.payment_method}</Template>
              )}
            </TableCell>

            <TableCell
              sx={{ border: "none", fontWeight: "bold" }}
              align="center"
            >
              {isItemSelected && isEditing ? (
                <SelectStatus
                  label={"payment_status"}
                  setEditedUser={setEditedUser}
                  value={editedUser.payment_status}
                  defaultValue={row.payment_status ? "paid" : "unpaid"}
                  setIsChange={setIsChange}
                />
              ) : row.payment_status ? (
                <Template>paid</Template>
              ) : (
                <Template>unpaid</Template>
              )}
            </TableCell>

            <TableCell
              sx={{ border: "none", fontWeight: "bold" }}
              align="center"
            >
              {isItemSelected && isEditing ? (
                <EditingCell
                inputLabel={"Contact info"}
                  label={"contact_info"}
                  setEditedUser={setEditedUser}
                  defaultValue={row.contact_info}
                  setIsChange={setIsChange}
                />
              ) : (
                <Template>{row.contact_info}</Template>
              )}
            </TableCell>

            <TableCell
              sx={{ border: "none", fontWeight: "bold" }}
              align="center"
            >
              <Manage k={row.key} login={row.email} password={row.password}/>
            </TableCell>
          </>
        )}
      </TableRow>
      {!matches && (
        <CollapseUserRow
          setAddUsers={setAddUsers}
          search={false}
          updateUser={updateUser}
          removeUser={removeUser}
          open={open}
          row={row}
          isNew={isNew}
          addUser={addUser}
        />
      )}
    </>
  );
}

export default UserRow;
