import React from "react";
import { Typography } from "@mui/material";

const KeyInfoDisplay = ({ data }) => {
  let usable = "No";
  let { status, used_date, message } = data;

  switch (status) {
    case "failed":
      status = "Idle";
      break;
    case "not_exist":
      status = "Invalid Key";
      break;
    case "success":
      status = "Account Upgraded";
      break;
    case "error":
      status = "Error";
      break;
    case "upgrade_processing":
      status = "Upgrade Processing";
      break;
    case "failed_upgrade":
      status = "Upgrade Failed";
      break;
    case "failed_renew":
      status = "Renew Failed";
      break;
      case "check_processing":
      status = "Key Processing";
      message = "Check back in a few seconds"
      break;
      case "in_queue":
      status = "Upgrade in queue";
      break;
    default:
      break;
  }

  if (status === "Idle" || status === "failed" || status === "replaced") {
    usable = "Yes";
  }


  if(!used_date){
    used_date = new Date('01-01-1970')
  } else {
    used_date = new Date(used_date)
  }

  return (
    <div >
      <Typography sx={{ fontSize: "22px", color: 'black' }} variant="h6" align="center">
        STATUS: {status}
      </Typography>
      {(message !== 'none' && message !== '') && <Typography sx={{ fontSize: "20px", color: 'black' }} variant="h6" align="center">
        Message: {message}
      </Typography>}
      <Typography sx={{ fontSize: "18px", color: 'black' }} variant="subtitle1" align="center">
        Usable: {usable}
      </Typography>
      <Typography
        sx={{ fontSize: "18px", color: 'black' }}
        gutterBottom
        variant="subtitle1"
        align="center"
      >
        LAST USE: {used_date.getTime() <= 0 ? "Never" : used_date.toLocaleString()}
      </Typography>
    </div>
  );
};

export default KeyInfoDisplay;