import { TextField } from '@mui/material'
import React from 'react'

function EditingCell({label, defaultValue, setEditedUser, setIsChange, inputLabel}) {
    const onChangeHandler = (e) => {
        setIsChange(true);
        setEditedUser(preValue => {
            return {...preValue, [label]: e.target.value}
        })
    }

  return (
    <TextField onChange={onChangeHandler} id="outlined-basic" label={inputLabel} defaultValue={defaultValue} variant="outlined">

    </TextField>
  )
}

export default EditingCell