import React, {useRef, useContext} from "react";
import { Modal, TextField, Box, Button, useMediaQuery } from "@mui/material";
import { tokenCtx } from "../../store/TokenProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function TokenModal({ open, setOpen }) {
  const matches = useMediaQuery("(min-width:960px)");
  const handleClose = () => setOpen(false);
  const tokenInputRef = useRef();
  const {tokenHandler} = useContext(tokenCtx)
 
  if (!matches) {
    style.width = "70%";
  } else {
    style.width = 500;
  }

  const onClickHandler = () => {
    setOpen(false);
    tokenHandler(tokenInputRef.current.value);
  }

  

  return (
    <Modal onClose={handleClose} open={open}>
      <Box sx={style}>
        <TextField
          inputRef={tokenInputRef}
          fullWidth
          id="standard-basic"
          label="Access Token"
          variant="standard"
        />
        <Button onClick={onClickHandler} sx={{ float: "right", margin: "20px 0 0" }} variant="contained">
          Use
        </Button>
      </Box>
    </Modal>
  );
}

export default TokenModal;
