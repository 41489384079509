import { Button } from "@mui/material";
import React from "react";

function RequestButton({
  text,
  k,
  login,
  password,
  renew,
  upgrade,
  info,
  setData,
  countryCode,
  setError,
}) {
  const submitRequest = () => {
    setData("");
    if (upgrade) {
      if (!countryCode) {
        setError("please choose a county.");
        return;
      }
    }

    let url;
    if (renew) {
      url = `https://api.spotifyman.com/?renew=${encodeURIComponent(k)}&login=${encodeURIComponent(login)}&pwd=${encodeURIComponent(password)}`;
    }
    if (upgrade) {
      url = `https://api.spotifyman.com/?upgrade=${k}&login=${encodeURIComponent(login)}&pwd=${encodeURIComponent(password)}&country=${encodeURIComponent(countryCode)}`;
    }
    if (info) {
      url = `https://api.spotifyman.com/?info=${encodeURIComponent(k)}`;
    }

    fetch(url)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          return res.json();
        } else {
          throw new Error("request failed");
        }
      })
      .then((data) => {
        setData({ ...data, renew: renew, info: info, upgrade: upgrade });
      })
      .catch((err) => {
        setError(err.message);
        console.log(err.message);
      });
  };
  return (
    <Button
      sx={{
        color: "black",
        borderRight: "1px grey solid",
        borderRadius: "2px",
      }}
      onClick={submitRequest}
      variant="Text"
    >
      {text}
    </Button>
  );
}

export default RequestButton;
