import React, {useState} from "react"

export const tokenCtx = React.createContext({
    token: '',
    tokenHandler(){}
})

const TokenProvider = (props) => {
    const [token, setToken] = useState(null);

    const tokenHandler = (token) => {
        setToken(token);
        localStorage.setItem('kids-access-token', token);
    }

    const context = {token, tokenHandler};

    return (
        <tokenCtx.Provider value={context}>
            {props.children}
        </tokenCtx.Provider>
    )
}

export default TokenProvider;