import { Typography } from "@mui/material";
import React from "react";

const RenewMessage = ({ data }) => {
  let { status, message } = data;

  switch (status) {
    case "not_exist":
      status = "Invalid Key";
      break;
    case "error":
      status = "Error";
      break;
    default:
      break;
  }

  return (
    <div style={{textAlign: 'center'}}>
      <Typography sx={{color: 'black'}} variant="h5" gutterBottom align="center">
        STATUS: {status}
      </Typography>
      {message && (
        <>
          <Typography sx={{color: 'black'}} variant="h5" align="center">
            Message:
          </Typography>
          <Typography color="black" gutterBottom variant="h6" align="center">
            {message}
          </Typography>
        </>
      )}
    </div>
  );
};

export default RenewMessage;