import { useState, useEffect, useContext } from "react";
import { tokenCtx } from "../../store/TokenProvider";
import { Collapse, Button, Table, TableCell, Typography } from "@mui/material";
import useValidate from "../../hooks/useValidate";
import React from "react";
import GetKeyInfoButton from "../PostUser/GetKeyInfoButton";
import IsEditingForm from "./IsEditingForm";
import Manage from "./ADD-ONS/Manage";

function CollapseUserRow({
  open,
  row,
  updateUser,
  removeUser,
  search,
  isNew,
  setAddUsers,
  addUser,
}) {
  const [isChanged, setIsChanged] = useState(false);
  const [rowData, setRowData] = useState({ ...row });
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isNew) {
      setIsEditing(true);
    }
  }, [isNew, setIsEditing]);

  const { token } = useContext(tokenCtx);
  const {
    onChangeHandler: onChangeNameHandler,
    inputRef: nameRef,
    isValid: isNameValid,
  } = useValidate((value) => {
    if (value.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeEmailHandler,
    inputRef: emailRef,
    isValid: isEmailValid,
  } = useValidate((value) => {
    if (
      value.trim().length !== 0 &&
      value.includes("@") &&
      value.includes(".")
    ) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeAmountHandler,
    inputRef: amountRef,
    isValid: isAmountValid,
  } = useValidate((value) => {
    if (value.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangePhoneHandler,
    inputRef: contactRef,
    isValid: isContactValid,
  } = useValidate((value) => {
    if (value.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeKeyHandler,
    inputRef: keyRef,
    isValid: isKeyValid,
  } = useValidate((value) => {
    const arr = value.split("-");

    if (arr.length === 4) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].length !== 4) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangePasswordHandler,
    inputRef: passwordRef,
    isValid: isPasswordValid,
  } = useValidate((value) => {
    if (value.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeMethodHandler,
    inputRef: methodRef,
    isValid: isMethodValid,
  } = useValidate((value) => {
    if (value.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  });

  const {
    onChangeHandler: onChangeStatusHandler,
    inputRef: statusRef,
    isValid: isStatusValid,
  } = useValidate((value) => {
    if (value.trim() === "paid" || value.trim() === "unpaid") {
      return true;
    } else {
      return false;
    }
  });

  //used to create new user
  const isFormValid =
    isAmountValid &&
    isEmailValid &&
    isNameValid &&
    isContactValid &&
    isKeyValid &&
    isMethodValid &&
    // isStatusValid &&
    isPasswordValid;

  useEffect(() => {
    if (!isEditing && isChanged && !isNew) {
      let body = { ...rowData };

      if (rowData.payment_status === "paid") {
        body.payment_status = true;
      } else {
        body.payment_status = false;
      }

      fetch(`https://admin.spotifyman.com/customers/${rowData._id}`, {
        method: "PATCH",
        body: JSON.stringify({ ...body }),
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            return res.json();
          }
          throw new Error("Failed updating user.");
        })
        .then((data) => {
          updateUser(rowData._id, data);
        })
        .catch((err) => console.log(err.message));
    }
  }, [isEditing, isChanged, token, rowData, isNew]);

  const removeUserHandler = () => {
    fetch(`https://admin.spotifyman.com/customers/${rowData._id}`, {
      method: "DELETE",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          return res.json();
        }
        throw new Error("Deleting Failed.");
      })
      .then((data) => {
        removeUser(rowData._id);
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    if (!isEditing && isChanged && isNew) {
      if (!isFormValid) return;

      const body = { ...rowData };
      if (rowData.payment_status === "paid") {
        body.payment_status = true;
      } else {
        body.payment_status = false;
      }

      fetch("https://admin.spotifyman.com/customers/", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if(res.status === 409){
            throw new Error('User is already exists please change email or username.');
          }
          if (res.status === 200 || res.status === 201) {
            console.log('created user?')
            return res.json();
          }
          throw new Error("Failed to upload the user!");
        })
        .then((data) => {
          addUser(data);
          setAddUsers((preValue) =>
            preValue.filter((user) => {
              return user._id !== rowData._id;
            })
          );
        }).catch((err) => {
          setIsEditing(preValue => !preValue)
          setError(err.message);
          console.log(err.message);
        });
    }
  }, [isEditing, isChanged, rowData, isFormValid, isNew]);

const key = rowData.key;
  return (
    <Collapse sx={{ padding: "15px" }} in={open}>
      <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
      <Typography sx={{color:"red", marginBottom: '20px'}} variant="subtitle1">
        {error}
      </Typography>
        <IsEditingForm
          inputLabel={"Name"}
          search={search}
          setIsChanged={setIsChanged}
          ref={nameRef}
          onChangeFunc={onChangeNameHandler}
          setRowData={setRowData}
          defaultValue={!isNew ? rowData.name : ""}
          label={"name"}
          isEditing={isEditing}
        />
        <IsEditingForm
          inputLabel={"Key"}
          setRowData={setRowData}
          setIsChanged={setIsChanged}
          ref={keyRef}
          onChangeFunc={onChangeKeyHandler}
          defaultValue={!isNew ? rowData.key : ""}
          label={"key"}
          isEditing={isEditing}
        />
        <IsEditingForm
          inputLabel={"Email"}
          setRowData={setRowData}
          setIsChanged={setIsChanged}
          ref={emailRef}
          onChangeFunc={onChangeEmailHandler}
          defaultValue={!isNew ? rowData.email : ""}
          label={"email"}
          isEditing={isEditing}
        />
        <IsEditingForm
          inputLabel={"Password"}
          setRowData={setRowData}
          setIsChanged={setIsChanged}
          ref={passwordRef}
          onChangeFunc={onChangePasswordHandler}
          defaultValue={!isNew ? rowData.password : ""}
          label={"password"}
          isEditing={isEditing}
        />
        <IsEditingForm
          inputLabel={"Paid Amount"}
          setRowData={setRowData}
          setIsChanged={setIsChanged}
          ref={amountRef}
          onChangeFunc={onChangeAmountHandler}
          defaultValue={!isNew ? rowData.paid_amount : ""}
          label={"paid_amount"}
          isEditing={isEditing}
        />
        <IsEditingForm
          inputLabel={"Payment Method"}
          setRowData={setRowData}
          setIsChanged={setIsChanged}
          ref={methodRef}
          onChangeFunc={onChangeMethodHandler}
          defaultValue={!isNew ? rowData.payment_method : ""}
          label={"payment_method"}
          isEditing={isEditing}
        />
        <IsEditingForm
          inputLabel={"Payment Status"}
          setRowData={setRowData}
          setIsChanged={setIsChanged}
          ref={statusRef}
          onChangeFunc={onChangeStatusHandler}
          value={rowData.payment_status}
          defaultValue={
            !isNew ? (rowData.payment_status ? "paid" : "unpaid") : ""
          }
          label={"payment_status"}
          isEditing={isEditing}
          isSelect={true}
        />
        <IsEditingForm
          inputLabel={"Contact Info"}
          setRowData={setRowData}
          setIsChanged={setIsChanged}
          ref={contactRef}
          onChangeFunc={onChangePhoneHandler}
          defaultValue={!isNew ? rowData.contact_info : ""}
          label={"contact_info"}
          isEditing={isEditing}
        />
       
        <Manage k={key} login={rowData.email} password={rowData.password}/>

        <div style={{ marginTop: "30px" }}>
          <Button
            sx={{ marginRight: "20px" }}
            disabled={isNew && !isFormValid && isEditing}
            variant="contained"
            onClick={() => {
              if(error === ''){
                setIsEditing((preValue) => !preValue);
              }
              
            }}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>

          {!isNew ? (
            <Button
              onClick={removeUserHandler}
              variant="contained"
              color="error"
            >
              Delete
            </Button>
          ) : (
            <Button
              onClick={() => {
                setAddUsers((preValue) => {
                  return preValue.filter((user) => {
                    return user._id !== rowData._id;
                  });
                });
              }}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
          )}
        </div>
      </Table>
    </Collapse>
  );
}

export default CollapseUserRow;
