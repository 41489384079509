import { useContext, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import { alpha } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import AcceptModal from "./UI/AcceptModal";
import { tokenCtx } from "../store/TokenProvider";

function EnhancedTableToolbar({
  isEditing,
  setIsEditing,
  removeUser,
  updateUsers,
  selectedCustomers,
  numSelected,
  handleCheckbox,
  isSelected,
  setAddUsers,
}) {
  const [openModal, setOpenModal] = useState(false);

  const { token } = useContext(tokenCtx);

  const onAddUser = () => {
    setAddUsers((preValue) => {
      return [...preValue, { _id: uuidv4() }];
    });
  };

  const deleteSelectedUsers = (selectedCustomers) => {
    for (let i = 0; i < selectedCustomers.length; i++) {
      fetch(
        `https://admin.spotifyman.com/customers/${selectedCustomers[i]._id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            return res.json();
          }
          throw new Error("Deleting Failed.");
        })
        .then((data) => {
          removeUser(selectedCustomers[i]._id);
        })
        .catch((err) => console.log(err.message));
    }
  };
  return (
    <div>
      <AcceptModal
        open={openModal}
        setOpen={setOpenModal}
        deleteSelectedUsers={deleteSelectedUsers}
        selectedCustomers={selectedCustomers}
      />
      <Toolbar
        style={{ paddingBottom: 0, paddingTop: 0 }}
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {/* {numSelected > 0 && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )} */}

        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Users
          </Typography>
        )}

        {numSelected > 0 ? (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setIsEditing((preValue) => !preValue);
                }}
              >
                {isEditing ? <DoneIcon /> : <EditIcon />}
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon
                  onClick={() => {
                    setOpenModal(true);
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Add User">
            <IconButton onClick={onAddUser}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </div>
  );
}

export default EnhancedTableToolbar;
