import React from "react";
import CustomersTable from "./CustomersTable";

const UsersList = ({
  setOpen,
  search,
  setUsers,
  users,
  errors,
  selected,
  setSelected,
  addUserHandler,
  removeUserHandler,
  replaceUpdatedUser,
}) => {
  let foundUsers = [...users];

  if (search !== "") {
    foundUsers = foundUsers.filter((u, index) => {
      return (
        (u.name.toLowerCase().includes(search.trim().toLowerCase()) ||
          u.email.toLowerCase().includes(search.trim().toLowerCase()) ||
          u.contact_info.toLowerCase().includes(search.trim().toLowerCase()) ||
          u.key.toLowerCase().includes(search.trim().toLowerCase())) &&
        search !== ""
      );
    });
  }

  return (
    <div style={{ padding: "20px" }}>

        <CustomersTable
          setOpen={setOpen}
          error={errors}
          setUsers={setUsers}
          users={foundUsers}
          selected={selected}
          setSelected={setSelected}
          addUserHandler={addUserHandler}
          removeUserHandler={removeUserHandler}
          replaceUpdatedUser={replaceUpdatedUser}
        />
    </div>
  );
};

export default UsersList;
