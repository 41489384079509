import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import useMediaQuery from "@mui/material/useMediaQuery";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "key",
    numeric: false,
    disablePadding: false,
    label: "Key",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "password",
    numeric: false,
    disablePadding: false,
    label: "Password",
  },
  {
    id: "paid_amount",
    numeric: false,
    disablePadding: false,
    label: "Paid amount",
  },
  {
    id: "payment_method",
    numeric: false,
    disablePadding: false,
    label: "Payment method",
  },
  {
    id: "payment_status",
    numeric: false,
    disablePadding: false,
    label: "Payment status",
  },
  {
    id: "contact_info",
    numeric: false,
    disablePadding: false,
    label: "Contact info",
  },
  {
    id: "Info",
    numeric: false,
    disablePadding: false,
    label: "Info",
  },
];

const EnhancedTableHead = (props) => {
  const matches = useMediaQuery("(min-width:960px)");
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
{     matches ? <>  <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected === rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
          sx={{padding: '16px'}}
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
          
            {headCell.label}
            
             
            
          </TableCell>
        ))}</> :
        <TableCell
            sx={{width: '100%'}}
            key={headCells[0].id}
            align={ "left" }
            padding={ "normal"}
            sortDirection={orderBy === headCells[0].id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCells[0].id}
              direction={orderBy === headCells[0].id ? order : "asc"}
              onClick={createSortHandler(headCells[0].id)}
            >
              {headCells[0].label}
              {orderBy === headCells[0].id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        
        }
      </TableRow>
    </TableHead>
  );
};

// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

export default EnhancedTableHead;
