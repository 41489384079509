import {useState} from 'react';
import React from 'react'

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: 'theme.palette.common.white',
      boxShadow: theme.shadows[1],
      fontSize: 12.5,
      padding: 8
    },
  }));

function Template({children}) {
    const [open, setOpen] = useState(false);
    const copyText = () => {
        setOpen(true)
        navigator.clipboard.writeText(children.trim());
    }

  return (
    <LightTooltip
    sx={{cursor: 'pointer'}}
    placement='top'
    leaveDelay={2000}
    onClose={() => setOpen(false)}
    open={open}
    title="Copied!"
    >
    <span onClick={copyText}>
        {children}
    </span>
    </LightTooltip>
  )
}

export default Template;