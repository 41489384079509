import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Box, useMediaQuery } from "@mui/material";

const SelectCountry = ({
  countryInputRef,
  onBlurContryHandler,
  onChangeCountryHandler,
  chosenCountry,
  setCountryMsg,
}) => {
  const [countries, setCountries] = useState([]);
  const matches = useMediaQuery("(max-width:490px)");

  useEffect(() => {
    fetch("https://api.spotifyman.com/?stock")
      .then(function (response) {
        return response.text();
      })
      .then((data) => {
        const givenCountries = JSON.parse(data);
        const formattedCountries = givenCountries.map((c) => {
          return { label: c.country, code: c.country_code, stock: c.slots };
        });
        setCountries(formattedCountries);
      })
      .catch(console.log);
  }, []);
  return (
    <div style={{ width: matches ? "90%" : "60%", margin: "30px auto 10px" }}>
      <Autocomplete
        value={chosenCountry.label}
        onChange={(e, value) => {
          setCountryMsg("");
          console.log("COuntry selected", value);
          if (!value) {
            return onChangeCountryHandler("");
          }
          return onChangeCountryHandler(value);
        }}
        // onBlur={onBlurContryHandler}
        options={countries}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.label}
          </Box>
        )}
        renderInput={(params) => {
          return (
            <>
              <TextField
                sx={{ label: { color: "black" }, input: { color: "black" } }}
                {...params}
                label="Choose a country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            </>
          );
        }}
      ></Autocomplete>
    </div>
  );
};

export default SelectCountry;
