import React from "react";
import {
  TableCell,
  MenuItem,
  Select,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";

function SelectStatus({ label, defaultValue, setEditedUser, setIsChange, value }) {
  const onChangeHandler = (e) => {
    setIsChange(true);
    setEditedUser((preValue) => {
      return { ...preValue, [label]: e.target.value };
    });
  };

  return (
    <div>
      <TableCell sx={{ border: "none", fontWeight: "bold" }} align="center">
        <Box sx={{ minWidth: 200 }}>
          <FormControl sx={{ width: "70%" }}>
            <InputLabel id="demo-simple-select-label">
              Payment Status
            </InputLabel>
            <Select
              label="Payment Status"
              onChange={onChangeHandler}
              value={value}
              defaultValue={defaultValue}
            >
              <MenuItem value={true}>paid</MenuItem>
              <MenuItem value={false}>unpaid</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </TableCell>
    </div>
  );
}

export default SelectStatus;
