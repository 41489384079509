import React from "react";
import Button from "@mui/material/Button";

function CopyButton({ value }) {
 
  return (
    <Button
      value={value}
      sx={{
        margin: "auto 5px",
        position: "absolute",
        top: "20%",
        padding: "5px 15px",
      }}
      onClick={() => {
        navigator.clipboard.writeText(value.trim());
      }}
      variant="outlined"
    >
      Copy
    </Button>
  );
}

export default CopyButton;
